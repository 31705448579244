html{
  height: 100%;
  display: flex;
  flex-direction: column;
}

body{
  height: 100%;
}

#root {
  flex: 1;
  height: 100%;
}

#main {
  height: 100%;
}

#tldraw{
  width: 100%;
  height: 100%;
  position: relative
}

.quill{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  width: 100%;
}

.ql-editor {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  /* padding-top: 60px; */
}

.ql-toolbar.ql-snow {
  position: sticky !important;
  top:0;
  width: 100%;
  z-index: 100;
  background-color: white;
}